
































































import type { Picture, Pagination } from '@/inc/types'
import { defineComponent, PropType, ref } from '@vue/composition-api'
import { cache, push } from '@/inc/utils'
import { getApiUrl } from '@/inc/app.config'

import UiPicture from '@/components/ui/Picture.vue'

interface SocialProjectsCard {
  title: string
  htmltext: string
  url: string
  picture: Picture
}
type SocialProjectsGrid = Pagination<SocialProjectsCard>

export default defineComponent({
  name: 'SocialProjectsGrid',
  components: { UiPicture },
  props: {
    content: {
      type: Object as PropType<SocialProjectsGrid>,
      required: true,
    },
  },

  setup(props, ctx) {
    const items = ref(props.content.content)
    const { $route } = ctx.root
    const isLoading = ref(false)
    let currentPage = 1

    // Track card clik
    const onCardClick = (card: SocialProjectsCard) => {
      push({
        event: 'card_click',
        ctaLabel: card.title,
        ctaType: 'social_project',
        ctaUrl: card.url,
      })
    }

    const onLoadMore = async () => {
      isLoading.value = true
      currentPage += 1

      const url = `${getApiUrl()}/pages/${
        $route.params.sub ? $route.params.sub : $route.params.slug
      }?page=${currentPage}`

      try {
        const res = await cache.fetch($route.fullPath, url)
        const { content } = res.data as {
          content: { items?: SocialProjectsGrid }
        }

        if (content.items?.content.length) {
          items.value.push(...content.items?.content)

          // Update URL with page param:
          // (To be confirmed)
          // const newUrl = new URL(window.location.href)

          // newUrl.searchParams.set('page', currentPage.toString())
          // window.history.replaceState({}, '', newUrl)
        }

        isLoading.value = false
      } catch (e) {
        isLoading.value = false
      }
    }

    return {
      items,
      isLoading,
      onCardClick,
      onLoadMore,
    }
  },
})
