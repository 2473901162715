




























import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'

import AWHero from '@/components/anders-wonen/Hero.vue'
import UiTabNav from '@/components/ui/TabNav.vue'
import SocialProjectsGrid from '@/components/social-projects/Grid.vue'

export default defineComponent({
  name: 'Sustainability',
  components: { AWHero, UiTabNav, SocialProjectsGrid },

  setup() {
    const { content } = useGetters(['content'])
    const components = {
      ctaJobs: () => import('@/components/cta/Jobs.vue'),
      ctaSocialProjects: () => import('@/components/cta/SocialProjects.vue'),
      highlighted2: () =>
        import('@/components/flexible/HighlightedSection.vue'),
      video: () => import('@/components/anders-wonen/Gallery.vue'),
      pictureTextDuo: () => import('@/components/flexible/DuoPicture.vue'),
      picture: () => import('@/components/flexible/Picture.vue'),
      quote: () => import('@/components/flexible/Quote.vue'),
      sustainabilityList: () => import('@/components/sustainability/List.vue'),
      sustainabilityListIcons: () =>
        import('@/components/sustainability/List.vue'),
      ctaType: () => import('@/components/cta/PreEncoded.vue'),
      galleryWtext: () => import('@/components/anders-wonen/Gallery.vue'),
      keyFacts: () => import('@/components/flexible/KeyList.vue'),
      keyFigures: () => import('@/components/flexible/KeyList.vue'),
      miniGallery: () => import('@/components/flexible/Gallery.vue'),
      timeline: () => import('@/components/flexible/Timeline.vue'),
      scrollingGallery: () =>
        import('@/components/flexible/ScrollingGallery.vue'),
      stackedPictures: () =>
        import('@/components/flexible/StackedPictures.vue'),
      socialProjectsList: () =>
        import('@/components/flexible/ScrollingGallery.vue'),
      ctaGallery: () => import('@/components/cta/Gallery.vue'),
    }

    return {
      content,
      components,
    }
  },
})
