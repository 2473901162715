






















import type { Link } from '@/inc/types'

import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'
import { pushCta, GtmCta } from '@/inc/utils'
import { onAppearDefault } from '@/inc/directives/appear'

export default defineComponent({
  name: 'UiTabNav',
  props: {
    content: {
      type: Array as PropType<Link[]>,
      required: true,
    },
  },
  setup() {
    const rootEl = ref<HTMLElement | null>(null)
    const backgroundLeft = ref('0.8rem')
    const backgroundWidth = ref('0rem')

    // Set height and top of background
    const updateBackground = (el: Element) => {
      if (!rootEl.value) {
        return
      }

      const { width, left } = el.getBoundingClientRect()
      const { left: rootLeft } = rootEl.value.getBoundingClientRect()

      backgroundLeft.value = `${(left - rootLeft) * 0.1}rem`
      backgroundWidth.value = `${width * 0.1}rem`
    }

    // Position background to active link parent li element
    const selectActiveLink = () => {
      const activeEl = rootEl.value?.querySelector('.router-link-exact-active')

      if (activeEl?.parentElement) {
        updateBackground(activeEl.parentElement)
      }
    }

    // Position background
    const onItemHighlight = (e: MouseEvent | FocusEvent) => {
      const { target } = e

      if (target instanceof Element) {
        updateBackground(target)
      }
    }

    // When the cursor leaves the tab, put highlight back on current page
    const onMouseLeave = () => {
      // Check if we just clicked to go to the other page
      const activeLink = rootEl.value?.querySelector('a:focus')

      if (!activeLink) {
        selectActiveLink()
      }
    }

    // Push event to datalayer on click
    const onClick = (link: Link) => {
      if (!rootEl.value) {
        return
      }

      const layer: Partial<GtmCta> = {
        ctaLabel: link.label,
        ctaType: 'tabnav',
        ctaUrl: link.url,
      }

      pushCta(layer, rootEl.value)
    }

    onMounted(() => {
      if (rootEl.value) {
        onAppearDefault(rootEl.value, {
          onStart: () => {
            selectActiveLink()
          },
          onComplete: () => {
            rootEl.value?.classList.add('is-ready')
          },
        })
      }
    })

    return {
      rootEl,
      backgroundLeft,
      backgroundWidth,
      onItemHighlight,
      onMouseLeave,
      onClick,
    }
  },
})
