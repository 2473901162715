



































import type { Picture } from '@/inc/types'

import gsap from 'gsap'
import { defineComponent, PropType, ref, onMounted } from '@vue/composition-api'

interface AWHero {
  title: string
  subtitle: string
  picture: Picture
  video: string
}

import UiLoader from '@/components/ui/Loader.vue'

export default defineComponent({
  name: 'AWHero',
  components: { UiLoader },
  props: {
    content: {
      type: Object as PropType<AWHero>,
      required: true,
    },
  },

  setup() {
    const rootElRef = ref<HTMLElement | null>(null)
    const imgElRef = ref<HTMLImageElement | null>(null)
    const videoElRef = ref<HTMLVideoElement | null>(null)
    const showPictureRef = ref(true)
    const hasAppearedRef = ref(false)
    const tl = gsap.timeline({
      onStart: () => (hasAppearedRef.value = true),
    })

    // Create appear animation
    const createTl = () => {
      if (!rootElRef.value) {
        return
      }

      const visualEl = rootElRef.value?.querySelector('.aw-hero__visual')
      const titleEls = rootElRef.value?.querySelectorAll('.aw-hero__title > *')

      tl.from(visualEl, {
        opacity: 0,
        scale: 1.2,
        duration: 1.5,
        ease: 'power4.out',
      }).from(
        titleEls,
        {
          y: 50,
          opacity: 0,
          duration: 1,
          stagger: 0.1,
          ease: 'power4.out',
        },
        '<+=1'
      )
    }

    // Play tl on image load
    const onImgLoad = () => {
      if (!hasAppearedRef.value) {
        tl.play()
      }
    }

    // Hide fallback picture on video load
    const onVideoLoad = () => {
      showPictureRef.value = false
      videoElRef.value?.play()

      if (!hasAppearedRef.value) {
        tl.play()
      }
    }

    onMounted(() => {
      createTl()

      // Wait for image to be loaded to show appear animation
      if (imgElRef.value) {
        // Check if image is already loaded
        if (imgElRef.value.complete) {
          onImgLoad()
        } else {
          imgElRef.value.onload = () => {
            onImgLoad()
          }
        }
      }

      // Wait for video load to hide fallback picture
      if (videoElRef.value) {
        // Check if video is already loaded
        if (videoElRef.value.readyState >= 3) {
          onVideoLoad()
        } else {
          videoElRef.value.onloadeddata = () => {
            onVideoLoad()
          }

          videoElRef.value.load()
        }
      }
    })

    return {
      rootElRef,
      videoElRef,
      showPictureRef,
      hasAppearedRef,
      onVideoLoad,
    }
  },
})
